var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-switch", {
                attrs: { label: "Soluzione Replicabile" },
                model: {
                  value: _vm.soluzioneReplicabile,
                  callback: function ($$v) {
                    _vm.soluzioneReplicabile = $$v
                  },
                  expression: "soluzioneReplicabile",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.referenzaClienteItems,
                  label: "Referenza Cliente",
                  required: "",
                  rules: [
                    function (v) {
                      return !!v || "Item is required"
                    },
                  ],
                  multiple: "",
                  chips: true,
                },
                model: {
                  value: _vm.referenzaCliente,
                  callback: function ($$v) {
                    _vm.referenzaCliente = $$v
                  },
                  expression: "referenzaCliente",
                },
              }),
              _c("v-text-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.checkDropDown(_vm.referenzaCliente),
                    expression: "checkDropDown(referenzaCliente)",
                  },
                ],
                attrs: { label: "Specificare Altro:" },
                model: {
                  value: _vm.altroReferenzaCliente,
                  callback: function ($$v) {
                    _vm.altroReferenzaCliente = $$v
                  },
                  expression: "altroReferenzaCliente",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.documentiMarketingItems,
                  label: "Documenti Tecnici Per Marketing",
                  required: "",
                  rules: [
                    function (v) {
                      return !!v || "Item is required"
                    },
                  ],
                  multiple: "",
                  chips: true,
                },
                model: {
                  value: _vm.documentiMarketing,
                  callback: function ($$v) {
                    _vm.documentiMarketing = $$v
                  },
                  expression: "documentiMarketing",
                },
              }),
              _c("v-text-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.checkDropDown(_vm.documentiMarketing),
                    expression: "checkDropDown(documentiMarketing)",
                  },
                ],
                attrs: { label: "Specificare Altro:" },
                model: {
                  value: _vm.altroDocumentiMarketing,
                  callback: function ($$v) {
                    _vm.altroDocumentiMarketing = $$v
                  },
                  expression: "altroDocumentiMarketing",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }