var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: { label: "Nome Cliente o Segmento Mercato" },
                model: {
                  value: _vm.nomeCliente,
                  callback: function ($$v) {
                    _vm.nomeCliente = $$v
                  },
                  expression: "nomeCliente",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: { label: "Cliente Finale (se diverso)" },
                model: {
                  value: _vm.clienteFinale,
                  callback: function ($$v) {
                    _vm.clienteFinale = $$v
                  },
                  expression: "clienteFinale",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: { label: "Descrizione Commessa" },
                model: {
                  value: _vm.descrizioneCommessa,
                  callback: function ($$v) {
                    _vm.descrizioneCommessa = $$v
                  },
                  expression: "descrizioneCommessa",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.responsabileItems,
                  label: "Responsabile Contrattuale Cliente",
                  required: "",
                  rules: [
                    function (v) {
                      return !!v || "Item is required"
                    },
                  ],
                },
                model: {
                  value: _vm.responsabile,
                  callback: function ($$v) {
                    _vm.responsabile = $$v
                  },
                  expression: "responsabile",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: { type: "date", label: "Data Inizio" },
                model: {
                  value: _vm.dataInizio,
                  callback: function ($$v) {
                    _vm.dataInizio = $$v
                  },
                  expression: "dataInizio",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: { type: "date", label: "Data Termine Prevista" },
                model: {
                  value: _vm.dataFine,
                  callback: function ($$v) {
                    _vm.dataFine = $$v
                  },
                  expression: "dataFine",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: { label: "Account Commerciale (ACOM)" },
                model: {
                  value: _vm.acom,
                  callback: function ($$v) {
                    _vm.acom = $$v
                  },
                  expression: "acom",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: { label: "Altri Riferimenti Cliente" },
                model: {
                  value: _vm.riferimenti,
                  callback: function ($$v) {
                    _vm.riferimenti = $$v
                  },
                  expression: "riferimenti",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-switch", {
                attrs: { label: "Anticipo Fornitura" },
                model: {
                  value: _vm.anticipoFornitura,
                  callback: function ($$v) {
                    _vm.anticipoFornitura = $$v
                  },
                  expression: "anticipoFornitura",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c(
                "v-radio-group",
                {
                  attrs: { label: "Luogo :", row: "" },
                  model: {
                    value: _vm.luogo,
                    callback: function ($$v) {
                      _vm.luogo = $$v
                    },
                    expression: "luogo",
                  },
                },
                [
                  _c("v-radio", { attrs: { label: "Proge", value: "Proge" } }),
                  _c("v-radio", {
                    attrs: { label: "Cliente", value: "Cliente" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.checkDropDown(_vm.luogo),
                    expression: "checkDropDown(luogo)",
                  },
                ],
                attrs: { label: "Indirizzo", "hide-details": "" },
                model: {
                  value: _vm.indirizzo,
                  callback: function ($$v) {
                    _vm.indirizzo = $$v
                  },
                  expression: "indirizzo",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-switch", {
                attrs: {
                  label:
                    "Privacy - Nomina Proge (Responsabile del Trattamento)",
                },
                model: {
                  value: _vm.privacy,
                  callback: function ($$v) {
                    _vm.privacy = $$v
                  },
                  expression: "privacy",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "5" } },
            [
              _c("v-textarea", {
                attrs: {
                  label: "Note",
                  rules: [
                    function (v) {
                      return !!v || "Item is required"
                    },
                  ],
                  rows: "2",
                  cols: "40",
                },
                model: {
                  value: _vm.note,
                  callback: function ($$v) {
                    _vm.note = $$v
                  },
                  expression: "note",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4", md: "2" } },
            [
              _c("pga-date-field", {
                attrs: {
                  "pga-date-field-value": _vm.dataInizio,
                  "nome-field": "Data Inizio test",
                  "hide-details": "",
                },
                model: {
                  value: _vm.PgaDateField,
                  callback: function ($$v) {
                    _vm.PgaDateField = $$v
                  },
                  expression: "PgaDateField",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }