var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    [
      _c(
        "v-card",
        { staticClass: "mb-12" },
        [
          _c(
            "v-card-title",
            [
              _vm._v(
                " Sezione 1 - RIESAME REQUISITI COMMESSA - ACCOUNT COMMERCIALE "
              ),
              _c("v-spacer"),
            ],
            1
          ),
          _c("pga-avvio-attivita-sezione1-details", {
            model: {
              value: _vm.PgaAvvioAttivitaSezione1Details,
              callback: function ($$v) {
                _vm.PgaAvvioAttivitaSezione1Details = $$v
              },
              expression: "PgaAvvioAttivitaSezione1Details",
            },
          }),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-12" },
        [
          _c(
            "v-card-title",
            [
              _vm._v(
                " Sezione 2 - RIESAME REQUISITI CONTRATTUALI - SERVIZI GENERALI "
              ),
              _c("v-spacer"),
            ],
            1
          ),
          _c("pga-avvio-attivita-sezione2-details", {
            model: {
              value: _vm.PgaAvvioAttivitaSezione2Details,
              callback: function ($$v) {
                _vm.PgaAvvioAttivitaSezione2Details = $$v
              },
              expression: "PgaAvvioAttivitaSezione2Details",
            },
          }),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-12" },
        [
          _c(
            "v-card-title",
            [
              _vm._v(" Sezione 3 – INFORMAZIONI MARKETING – SERVIZI GENERALI "),
              _c("v-spacer"),
            ],
            1
          ),
          _c("pga-avvio-attivita-sezione3-details", {
            model: {
              value: _vm.PgaAvvioAttivitaSezione3Details,
              callback: function ($$v) {
                _vm.PgaAvvioAttivitaSezione3Details = $$v
              },
              expression: "PgaAvvioAttivitaSezione3Details",
            },
          }),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-12" },
        [
          _c(
            "v-card-title",
            [
              _vm._v(" Sezione 4 - COMPOSIZIONE TEAM - ACCOUNT COMMERCIALE "),
              _c("v-spacer"),
            ],
            1
          ),
          _c("pga-avvio-attivita-sezione4Header-details", {
            model: {
              value: _vm.PgaAvvioAttivitaSezione4HeaderDetails,
              callback: function ($$v) {
                _vm.PgaAvvioAttivitaSezione4HeaderDetails = $$v
              },
              expression: "PgaAvvioAttivitaSezione4HeaderDetails",
            },
          }),
          _c("pga-avvio-attivita-sezione4-details", {
            model: {
              value: _vm.PgaAvvioAttivitaSezione4Details,
              callback: function ($$v) {
                _vm.PgaAvvioAttivitaSezione4Details = $$v
              },
              expression: "PgaAvvioAttivitaSezione4Details",
            },
          }),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-12" },
        [
          _c(
            "v-card-title",
            [
              _vm._v(" Sezione 5 – VARIAZIONI TEAM – ACCOUNT COMMERCIALE "),
              _c("v-spacer"),
            ],
            1
          ),
          _c("pga-avvio-attivita-sezione5Header-details", {
            model: {
              value: _vm.PgaAvvioAttivitaSezione5HeaderDetails,
              callback: function ($$v) {
                _vm.PgaAvvioAttivitaSezione5HeaderDetails = $$v
              },
              expression: "PgaAvvioAttivitaSezione5HeaderDetails",
            },
          }),
          _c("pga-avvio-attivita-sezione5-details", {
            model: {
              value: _vm.PgaAvvioAttivitaSezione5Details,
              callback: function ($$v) {
                _vm.PgaAvvioAttivitaSezione5Details = $$v
              },
              expression: "PgaAvvioAttivitaSezione5Details",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }