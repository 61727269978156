var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "1" } },
            [
              _c("v-chip", { staticClass: "ma-2", attrs: { label: "" } }, [
                _vm._v("Protocolli"),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: { label: "Contratto", readonly: "", "hide-details": "" },
                model: {
                  value: _vm.contratto,
                  callback: function ($$v) {
                    _vm.contratto = $$v
                  },
                  expression: "contratto",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: { label: "Ordine", readonly: "", "hide-details": "" },
                model: {
                  value: _vm.ordine,
                  callback: function ($$v) {
                    _vm.ordine = $$v
                  },
                  expression: "ordine",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Nome Repository Documentale",
                  readonly: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.nomeRepository,
                  callback: function ($$v) {
                    _vm.nomeRepository = $$v
                  },
                  expression: "nomeRepository",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Piano di Fatturazione",
                  readonly: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.pianoFatturazione,
                  callback: function ($$v) {
                    _vm.pianoFatturazione = $$v
                  },
                  expression: "pianoFatturazione",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Tipologia Ordine",
                  readonly: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.tipologiaOrdine,
                  callback: function ($$v) {
                    _vm.tipologiaOrdine = $$v
                  },
                  expression: "tipologiaOrdine",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "MOL non inferiore al minimo",
                  readonly: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.mol,
                  callback: function ($$v) {
                    _vm.mol = $$v
                  },
                  expression: "mol",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-textarea", {
                attrs: {
                  label: "Vincoli Contrattuali",
                  readonly: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.vincoli,
                  callback: function ($$v) {
                    _vm.vincoli = $$v
                  },
                  expression: "vincoli",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.checkDropDown(_vm.vincoli),
                    expression: "checkDropDown(vincoli)",
                  },
                ],
                attrs: {
                  label: "Specificato:",
                  readonly: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.subVincoli,
                  callback: function ($$v) {
                    _vm.subVincoli = $$v
                  },
                  expression: "subVincoli",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _vm.checkDropDown(_vm.vincoli) && _vm.subVincoli == "ALTRO"
                ? _c("v-text-field", {
                    attrs: {
                      label: "Altro:",
                      readonly: "",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.altroVincolo,
                      callback: function ($$v) {
                        _vm.altroVincolo = $$v
                      },
                      expression: "altroVincolo",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "4" } },
            [
              _c("v-textarea", {
                attrs: {
                  label: "Note",
                  readonly: "",
                  "hide-details": "",
                  rules: [
                    function (v) {
                      return !!v || "Item is required"
                    },
                  ],
                  rows: "1",
                },
                model: {
                  value: _vm.note2,
                  callback: function ($$v) {
                    _vm.note2 = $$v
                  },
                  expression: "note2",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }