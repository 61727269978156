var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3", "no-gutters": "" } },
            [
              _c("v-text-field", {
                attrs: { label: "PM" },
                model: {
                  value: _vm.pm,
                  callback: function ($$v) {
                    _vm.pm = $$v
                  },
                  expression: "pm",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "2" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.molItems,
                  label: "MOL non inferiore al minimo",
                  required: "",
                  rules: [
                    function (v) {
                      return !!v || "Item is required"
                    },
                  ],
                },
                model: {
                  value: _vm.mol,
                  callback: function ($$v) {
                    _vm.mol = $$v
                  },
                  expression: "mol",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }