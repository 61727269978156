var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    [
      _c(
        "v-stepper",
        {
          model: {
            value: _vm.e1,
            callback: function ($$v) {
              _vm.e1 = $$v
            },
            expression: "e1",
          },
        },
        [
          _c(
            "v-stepper-step",
            {
              attrs: {
                editable: _vm.editable,
                complete: _vm.e1 > 1,
                step: "1",
              },
            },
            [
              _vm._v(
                "Sezione 1 - RIESAME REQUISITI COMMESSA - ACCOUNT COMMERCIALE"
              ),
            ]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: "1" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-12", attrs: { tile: "" } },
                [
                  _c("pga-avvio-attivita-sezione-1-create", {
                    model: {
                      value: _vm.PgaAvvioAttivitaSezione1Create,
                      callback: function ($$v) {
                        _vm.PgaAvvioAttivitaSezione1Create = $$v
                      },
                      expression: "PgaAvvioAttivitaSezione1Create",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-stepper-step",
            {
              attrs: {
                editable: _vm.editable,
                complete: _vm.e1 > 2,
                step: "2",
              },
            },
            [
              _vm._v(
                "Sezione 2 - RIESAME REQUISITI CONTRATTUALI - SERVIZI GENERALI"
              ),
            ]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: "2" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-12", attrs: { tile: "" } },
                [
                  _c("pga-avvio-attivita-sezione-2-create", {
                    model: {
                      value: _vm.PgaAvvioAttivitaSezione2Create,
                      callback: function ($$v) {
                        _vm.PgaAvvioAttivitaSezione2Create = $$v
                      },
                      expression: "PgaAvvioAttivitaSezione2Create",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-stepper-step",
            {
              attrs: {
                editable: _vm.editable,
                complete: _vm.e1 > 3,
                step: "3",
              },
            },
            [_vm._v("Sezione 3 - INFORMAZIONI MARKETING - SERVIZI GENERALI")]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: "3" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-12", attrs: { tile: "" } },
                [
                  _c("pga-avvio-attivita-sezione-3-create", {
                    model: {
                      value: _vm.PgaAvvioAttivitaSezione3Create,
                      callback: function ($$v) {
                        _vm.PgaAvvioAttivitaSezione3Create = $$v
                      },
                      expression: "PgaAvvioAttivitaSezione3Create",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-stepper-step",
            {
              attrs: {
                editable: _vm.editable,
                complete: _vm.e1 > 4,
                step: "4",
              },
            },
            [_vm._v("Sezione 4 - COMPOSIZIONE TEAM - ACCOUNT COMMERCIALE")]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: "4" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-12", attrs: { tile: "" } },
                [
                  _c("pga-avvio-attivita-sezione-4-header-create", {
                    model: {
                      value: _vm.PgaAvvioAttivitaSezione4HeaderCreate,
                      callback: function ($$v) {
                        _vm.PgaAvvioAttivitaSezione4HeaderCreate = $$v
                      },
                      expression: "PgaAvvioAttivitaSezione4HeaderCreate",
                    },
                  }),
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-expansion-panels",
                        { attrs: { focusable: "" } },
                        _vm._l(
                          _vm.PgaAvvioAttivitaSezione4Create,
                          function (input, idx) {
                            return _c("pga-avvio-attivita-sezione-4-create", {
                              key: idx,
                              attrs: { index: idx },
                              model: {
                                value: _vm.PgaAvvioAttivitaSezione4Create[idx],
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.PgaAvvioAttivitaSezione4Create,
                                    idx,
                                    $$v
                                  )
                                },
                                expression:
                                  "PgaAvvioAttivitaSezione4Create[idx]",
                              },
                            })
                          }
                        ),
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            absolute: "",
                            dark: "",
                            fab: "",
                            bottom: "",
                            right: "",
                            color: "pink",
                          },
                          on: { click: _vm.addSezione4 },
                        },
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-stepper-step",
            {
              attrs: {
                editable: _vm.editable,
                complete: _vm.e1 > 5,
                step: "5",
              },
            },
            [_vm._v("Sezione 5 – VARIAZIONI TEAM – ACCOUNT COMMERCIALE")]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: "5" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-12", attrs: { tile: "" } },
                [
                  _c("pga-avvio-attivita-sezione-5-header-create", {
                    model: {
                      value: _vm.PgaAvvioAttivitaSezione5HeaderCreate,
                      callback: function ($$v) {
                        _vm.PgaAvvioAttivitaSezione5HeaderCreate = $$v
                      },
                      expression: "PgaAvvioAttivitaSezione5HeaderCreate",
                    },
                  }),
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-expansion-panels",
                        { attrs: { focusable: "" } },
                        _vm._l(
                          _vm.PgaAvvioAttivitaSezione5Create,
                          function (input, idx) {
                            return _c("pga-avvio-attivita-sezione-5-create", {
                              key: idx,
                              attrs: { index: idx },
                              model: {
                                value: _vm.PgaAvvioAttivitaSezione5Create[idx],
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.PgaAvvioAttivitaSezione5Create,
                                    idx,
                                    $$v
                                  )
                                },
                                expression:
                                  "PgaAvvioAttivitaSezione5Create[idx]",
                              },
                            })
                          }
                        ),
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            absolute: "",
                            dark: "",
                            fab: "",
                            bottom: "",
                            right: "",
                            color: "pink",
                          },
                          on: { click: _vm.addSezione5 },
                        },
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }