var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "pga-bottom-navigation",
        [
          _c(
            "pga-authorized-content",
            { attrs: { permission: "CreateUser" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { value: _vm.buttons["create"].Value },
                  on: {
                    click: function ($event) {
                      return _vm.Navigate(_vm.buttons["create"].Url)
                    },
                  },
                },
                [
                  _vm.buttons["create"].Text
                    ? _c("span", [_vm._v(_vm._s(_vm.buttons["create"].Text))])
                    : _vm._e(),
                  _c("v-icon", [_vm._v(_vm._s(_vm.buttons["create"].Icon))]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _vm._v(" Avvio Attività "),
              _c("v-spacer"),
              _c("v-text-field", {
                attrs: {
                  "append-icon": "mdi-magnify",
                  label: "Search",
                  "single-line": "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.items,
              search: _vm.search,
            },
            on: { "click:row": _vm.details },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }