var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: { label: "PM", readonly: "", "hide-details": "" },
                model: {
                  value: _vm.pm,
                  callback: function ($$v) {
                    _vm.pm = $$v
                  },
                  expression: "pm",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: { label: "Mol", readonly: "", "hide-details": "" },
                model: {
                  value: _vm.mol,
                  callback: function ($$v) {
                    _vm.mol = $$v
                  },
                  expression: "mol",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }